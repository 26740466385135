body{
  background: url(https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA1L2pvYjE4MDgtcmVtaXgtMDlhLWMuanBn.jpg);
  background-repeat: no-repeat;
  background-position: center;
}

.App {
  text-align: center;
  margin: 20px;
  font-family: 'Noto Sans Tamil', sans-serif; /* Apply the Tamil font */
}

.letters {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.letter {
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin: 5px;
  width: 50px;
  text-align: center;
  font-size: 24px;
  cursor: grab;
  user-select: none; /* Prevent text selection */
}

.letter:active {
  cursor: grabbing;
}

.hint-image{
  width: 150px;
  height: 150px;
}

.top-menu{
  padding: 10px;
}

.top-menu .logo-menu{
  position: fixed;
  left: 0;
  top: 0;
  margin-left: 10px;
  margin-top: 10px;
}

.top-menu .logo-menu img{
  width: 100px;
  height: 30px;
}

.top-menu .category-menu{
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  color: black;
  text-decoration: none;
  font-weight: bold;
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
}

.top-menu .category-menu img{
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.paging-game-page{
  padding: 10px;
}

.paging-game-page .forward, .paging-game-page .backward{
  display: inline-block;
  position: absolute;
  background-image: linear-gradient(to right top, #dde1e8, #dfe9ed, #e5f1ef, #f1f7ef, #fffcf3);
  box-shadow: #000 3px 3px 3px;
  padding: 10px;
  border-radius: 10px;
}

.paging-game-page .forward{
  right: 0;
  margin-right: 10px;
}

.paging-game-page .backward{
  left: 0;
  margin-left: 10px;
}

.paging-game-page .forward img, .paging-game-page .backward img{
  width: 40px;
  height: 40px;
}